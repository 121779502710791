import React from 'react'
import { Box, Flex, Link, Spacer, Image, Divider } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { FaLightbulb, FaFireAlt } from 'react-icons/fa'

export const Header: React.FC = () => {
  return (
    <Box bg="teal.500" py={4}>
      <Flex maxW="container.lg" mx="auto" px={4} alignItems="center">
        <Link as={RouterLink} to="/" display="flex" alignItems="center">
          <Image src="../mr-yogi-transparent.png" alt="Mr. Yogi" boxSize="40px" mr={2} />
        </Link>
        <Spacer />
        <Link
          as={RouterLink}
          to="/popular-questions"
          mr={4}
          color="white"
          display="flex"
          alignItems="center"
        >
          <FaFireAlt style={{ marginRight: '8px' }} />
          Popular Questions
        </Link>
        <Divider orientation="vertical" h="20px" mx={2} borderColor="white" />
        <Link
          href="https://mryogi.canny.io/feature-requests"
          isExternal
          color="white"
          display="flex"
          alignItems="center"
        >
          <FaLightbulb style={{ marginRight: '8px' }} />
          Suggest a feature
        </Link>
      </Flex>
    </Box>
  )
}

export default Header
