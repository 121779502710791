import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  colors: {
    primary: {
      50: '#e6f8fa',
      100: '#c1ebed',
      200: '#99dedf',
      300: '#70d1d2',
      400: '#48c5c4',
      500: '#2facab',
      600: '#248685',
      700: '#19605e',
      800: '#0d3b38',
      900: '#001512',
    },
    secondary: {
      50: '#f5f7fa',
      100: '#dfe3e8',
      200: '#c8cfd5',
      300: '#b1bac3',
      400: '#9aa6b0',
      500: '#808d96',
      600: '#646f77',
      700: '#485157',
      800: '#2b3336',
      900: '#0f1516',
    },
    accent: {
      50: '#fef8f3',
      100: '#fdeedc',
      200: '#fce4c3',
      300: '#fbd9ab',
      400: '#facf92',
      500: '#f9c479',
      600: '#c79d5e',
      700: '#956f45',
      800: '#63472b',
      900: '#312012',
    },
  },
  fonts: {
    heading: '"Roboto", sans-serif',
    body: '"Roboto", sans-serif',
    mono: '"Merriweather", serif',
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'bold',
      },
      sizes: {
        md: {
          h: 8,
          fontSize: 'md',
          px: 6,
        },
      },
      variants: {
        solid: {
          bg: 'primary.500',
          color: 'white',
          _hover: {
            bg: 'primary.600',
          },
        },
        outline: {
          borderColor: 'primary.500',
          color: 'primary.500',
          _hover: {
            bg: 'primary.100',
          },
        },
      },
    },
    Card: {
      baseStyle: {
        bg: 'secondary.50',
        p: 4,
        rounded: 'md',
        shadow: 'md',
      },
    },
  },
})

export default theme
