import { Box, Container, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import React from 'react'

export const Footer = () => {
  return (
    <Box bg={useColorModeValue('gray.100', 'gray.900')} color="gray.600" py={4}>
      <Container as={Stack} maxW={'6xl'} py={4} spacing={4} justify={'center'} align={'center'}>
        <Text>© 2024 Ask Mr. Yogi. All rights reserved.</Text>
      </Container>
    </Box>
  )
}
