import React from 'react'
import { Box } from '@chakra-ui/react'
import { Footer } from './footer.component'
import { Header } from './header.component'

interface LayoutProps {
  children: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Box>
      <Header />
      <Box as="main" minH="calc(100vh - 120px)">
        {' '}
        {/* Adjust the height as needed */}
        {children}
      </Box>
      <Footer />
    </Box>
  )
}

export default Layout
