import { Box, Container, Flex, Image, Spinner, Text, VStack, IconButton } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { UpvoteDownvote } from './upvote-downvote.component'
import { FaHeart, FaRegHeart } from 'react-icons/fa'

export const QuestionPage = () => {
  const { slug } = useParams()
  const [isLoading, setIsLoading] = useState(false) // State to manage loading state
  const [question, setQuestion] = useState(null)
  const [isLiked, setLiked] = useState(false) // State to manage the liked status

  useEffect(() => {
    console.log('Fetching data for slug:', slug) // Add this line
    const fetchData = async () => {
      setIsLoading(true) // Set loading to true
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE}/question/${slug}`)
        const data = await response.json()
        setQuestion(data)

        // Set the document title to the question text
        if (data && data.text) {
          document.title = data.text
        }
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [slug])

  const toggleLike = async () => {
    if (!isLiked) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE}/like`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            questionId: question.id,
          }),
        })

        if (response.ok) {
          // Toggle liked state after successful response
          setLiked(!isLiked)
        } else {
          console.error('Failed to like/unlike the question.')
        }
      } catch (error) {
        console.error('Error liking/unliking the question:', error)
      }
    }
  }

  return (
    <Container maxW="container.md" pt={10}>
      <VStack spacing={8}>
        <Image src="../mr-yogi-transparent.png" alt="Mr. Yogi" boxSize="300px" margin="auto" />
        {question && (
          <>
            <Box mt={4} p={5} shadow="md" borderWidth="1px" borderRadius="md" w="100%">
              <Flex justify="space-between" align="center">
                <Text fontWeight="bold">Question:</Text>
                {/* Heart Button for favoriting */}
                <IconButton
                  icon={isLiked ? <FaHeart color="red" /> : <FaRegHeart />}
                  onClick={toggleLike}
                  variant="ghost"
                  aria-label="Like question"
                />
              </Flex>
              <Text mt={2}>{question.text}</Text>
            </Box>
            <Box mt={4} p={5} shadow="md" borderWidth="1px" borderRadius="md">
              <Text fontWeight="bold">The Oracle says:</Text>
              <Box
                mt={2}
                dangerouslySetInnerHTML={{
                  __html: question.answers[0].text.replace(/\n/g, '<br>'),
                }}
              />
              <UpvoteDownvote answer={question.answers[0]} />
            </Box>
          </>
        )}
        {isLoading && (
          <Flex
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            backgroundColor="rgba(255, 255, 255, 0.8)"
            zIndex="overlay"
            alignItems="center"
            justifyContent="center"
          >
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="teal.500"
              size="xl"
            />
          </Flex>
        )}
      </VStack>
    </Container>
  )
}
