import { Box, Container, Image, VStack } from '@chakra-ui/react'
import React from 'react'
import { QuestionForm } from './question-form.component'

export const HomePage = () => {
  return (
    <Box>
      <Container maxW="container.md" pt={10}>
        <VStack spacing={8}>
          <Image src="./mr-yogi-transparent.png" alt="Mr. Yogi" boxSize="300px" margin="auto" />

          <QuestionForm />
        </VStack>
      </Container>
    </Box>
  )
}
