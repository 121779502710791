import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  FormControl,
  FormLabel,
  Textarea,
  Button,
  useToast,
  Box,
  Spinner,
  Flex,
} from '@chakra-ui/react'
import ReactGA from 'react-ga'

export const QuestionForm = () => {
  const [question, setQuestion] = useState('')
  const [isLoading, setIsLoading] = useState(false) // State to manage loading state
  const toast = useToast()
  const navigate = useNavigate() // Initialize useNavigate
  const BUTTON_LABEL = 'Ask the Oracle'

  const textareaRef = useRef(null) // Create a reference for the Textarea

  useEffect(() => {
    textareaRef.current?.focus() // Focus the Textarea on page load
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()
    setIsLoading(true) // Set loading to true

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE}/question`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question }),
      })
      const data = await response.json()
      setIsLoading(false) // Set loading to false
      if (data) {
        // Assuming the response contains the slug, replace 'data.slug' with the actual path
        navigate(`/question/${data.slug}`)
      } else {
        throw new Error('No answer received')
      }
    } catch (error) {
      console.error(error)
      setIsLoading(false) // Ensure loading is set to false on error
      toast({
        title: 'An error occurred.',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    } finally {
      ReactGA.event({
        category: 'User',
        action: 'Button Clicked',
        label: BUTTON_LABEL,
      })
    }
  }

  return (
    <Box position="relative">
      <FormControl as="form" onSubmit={handleSubmit} id="question-form">
        <FormLabel htmlFor="query" display="block" textAlign="center">
          Dear truth seeker, what do you wish to know?
        </FormLabel>
        <Textarea
          ref={textareaRef} // Attach the reference to the Textarea
          id="query"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="Enter your query here..."
          size="md"
          resize="vertical"
          isRequired
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault() // Prevent default Enter behavior (new line)
              handleSubmit(e) // Submit the form
            }
          }}
        />

        <Button m={4} colorScheme="teal" type="submit" isDisabled={isLoading}>
          {BUTTON_LABEL}
        </Button>
      </FormControl>
      {isLoading && (
        <Flex
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          backgroundColor="rgba(255, 255, 255, 0.8)"
          zIndex="overlay"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="teal.500" size="xl" />
        </Flex>
      )}
    </Box>
  )
}
