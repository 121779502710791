import { FC, lazy, useEffect } from 'react'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'
import { HomePage } from 'pages/home/home.page'
import React from 'react'
import { QuestionPage } from '../pages/question/question.page'
import ReactGA from 'react-ga'
import PopularQuestions from '../pages/popular/popular-questions.component'
import Layout from '../components/layout.component'

const Page404 = lazy(() => import('pages/Page404'))

const usePageViews = () => {
  const location = useLocation()

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search)
  }, [location])
}

const Routing: FC = () => {
  return (
    <BrowserRouter>
      <PageViewsWrapper />
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/question/:slug" element={<QuestionPage />} />
          <Route path="*" element={<Page404 />} />
          <Route path="/popular-questions" element={<PopularQuestions />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  )
}

const PageViewsWrapper: FC = () => {
  usePageViews()
  return null
}

export default Routing
