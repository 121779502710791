import React, { useEffect, useState } from 'react'
import { Box, Heading, VStack, Text, Spinner, Link, Flex, Spacer, Collapse } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { FaHeart, FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { IQuestion } from '../../interfaces/question.interface'
import DOMPurify from 'dompurify'

const PopularQuestions: React.FC = () => {
  const [questions, setQuestions] = useState<IQuestion[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [openQuestions, setOpenQuestions] = useState<{ [key: string]: boolean }>({})

  useEffect(() => {
    const fetchPopularQuestions = async () => {
      setIsLoading(true)
      setError(null)
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE}/questions/popular?limit=10`)
        if (!response.ok) {
          throw new Error('Failed to fetch popular questions')
        }
        const data = await response.json()
        setQuestions(data)
      } catch (error) {
        console.error('Error fetching popular questions:', error)
        setError('Failed to load popular questions. Please try again later.')
      } finally {
        setIsLoading(false)
      }
    }

    fetchPopularQuestions()
  }, [])

  const toggleQuestion = (id: string) => {
    setOpenQuestions((prev) => ({ ...prev, [id]: !prev[id] }))
  }

  const truncateAndSanitize = (html: string, wordCount: number) => {
    const div = document.createElement('div')
    div.innerHTML = DOMPurify.sanitize(html)
    const text = div.textContent || div.innerText
    const truncated = text.split(' ').slice(0, wordCount).join(' ')
    return truncated + (text.split(' ').length > wordCount ? '...' : '')
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    )
  }

  if (error) {
    return (
      <Box p={5}>
        <Text color="red.500">{error}</Text>
      </Box>
    )
  }

  return (
    <Box p={5}>
      <Heading mb={4}>Popular Questions</Heading>
      <VStack align="stretch" spacing={4}>
        {questions.map((question) => (
          <Box
            key={question.id}
            borderWidth="1px"
            borderRadius="lg"
            p={4}
            onClick={() => toggleQuestion(question.id)}
            cursor="pointer"
            transition="all 0.2s"
            _hover={{ bg: 'gray.50' }}
          >
            <Flex alignItems="center">
              <Heading size="md">{question.text}</Heading>
              <Spacer />
              <Flex alignItems="center" color="red.500" mr={2}>
                <FaHeart style={{ marginRight: '4px' }} />
                <Text>{question.likesCount}</Text>
              </Flex>
              {openQuestions[question.id] ? <FaChevronUp /> : <FaChevronDown />}
            </Flex>
            <Collapse in={openQuestions[question.id]} animateOpacity>
              <Box mt={4}>
                <Text>{truncateAndSanitize(question.answers[0].text, 20)}</Text>
                <Link as={RouterLink} to={`/question/${question.slug}`} color="teal.500">
                  Read more
                </Link>
              </Box>
            </Collapse>
          </Box>
        ))}
      </VStack>
    </Box>
  )
}

export default PopularQuestions
