import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import 'assets/styles/index.css'
import { initializeAnalytics } from './analytics'
import { initializeErrorReporting } from './error-reporting'

const container = document.getElementById('root')
const root = createRoot(container!)

initializeAnalytics()
initializeErrorReporting()

root.render(<App />)
