import { Suspense } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { store, persistor } from 'redux-store'
import { Loading } from 'shared'
import Routing from 'routes'
import React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import theme from './theme'

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Suspense fallback={<Loading />}>
        <Provider store={store}>
          <PersistGate loading={''} persistor={persistor}>
            <Routing />
          </PersistGate>
        </Provider>
      </Suspense>
    </ChakraProvider>
  )
}

export default App
