import React, { FC, useState } from 'react'
import { HStack, IconButton, Text } from '@chakra-ui/react'
import { TriangleUpIcon, TriangleDownIcon } from '@chakra-ui/icons'
import { IAnswer } from '../../interfaces/answer.interface'

export interface IUpvoteDownvoteProps {
  answer: IAnswer
}

export const UpvoteDownvote: FC<IUpvoteDownvoteProps> = ({ answer }) => {
  const [upvotes, setUpvotes] = useState(answer.numUpvotes)
  const [downvotes, setDownvotes] = useState(answer.numDownvotes)

  const handleUpvote = async () => {
    setUpvotes(upvotes + 1)

    // Make API call to upvote answer
    await fetch(`${process.env.REACT_APP_API_BASE}/upvote`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ answerId: answer.id }),
    })
  }

  const handleDownvote = async () => {
    setDownvotes(downvotes + 1)

    await fetch(`${process.env.REACT_APP_API_BASE}/downvote`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ answerId: answer.id }),
    })
  }

  const votes = upvotes - downvotes

  return (
    <HStack alignItems="center" bg="gray" borderRadius="16" width="fit-content">
      <IconButton
        aria-label="Upvote"
        icon={<TriangleUpIcon />}
        onClick={handleUpvote}
        variant="unstyled"
        size="lg"
        _hover={{ color: 'teal.500' }}
      />
      <Text fontSize="md" fontWeight="bold" color="white" mx="2">
        {`${votes} ${votes === 1 ? 'vote' : 'votes'}`}
      </Text>
      <IconButton
        aria-label="Downvote"
        icon={<TriangleDownIcon />}
        onClick={handleDownvote}
        variant="unstyled"
        size="lg"
        _hover={{ color: 'red.500' }}
      />
    </HStack>
  )
}
